// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import classnames from 'classnames'

import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { compiler } from 'markdown-to-jsx'

import Slider from '../slider'
import '../slider/style.less'

import GalleryParser from './gallery-parser'

import Audio from '../audio'
import '../audio/style.less'

import Video from '../video'
import '../video/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** BlockParser */
const BlockParser = ({ block, locale }) => {
  let {
    type,
    subType,
    imgSubType,
    lang,
    content,
    resources,
    resource,
    caption,
  } = block

  if (isNull(lang) || isUndefined(lang)) {
    lang = 'en'
  }

  let returnThis = <Fragment />

  if (lang === locale) {
    if (type === 'space') {
      returnThis = <br />
    }

    if (type === 'carousel') {
      returnThis = (
        <Slider className={classnames(subType)} resources={resources} />
      )
    }

    if (type === 'info') {
      returnThis = (
        <div className={classnames('flow info', subType)}>
          {map(resources, (r) => (
            <Fragment>
              {r.type === 'text' && (
                <p className={classnames(r.subType)}>
                  {compiler(r.content, { wrapper: null })}
                </p>
              )}
              {r.type === 'image' && isNull(r.caption) === false && (
                <div className={classnames('flow with-caption', r.subType)}>
                  <GatsbyImage
                    className={classnames(r.imgSubType)}
                    image={getImage(r.resource)}
                  />
                  <p className="caption">
                    {compiler(r.caption, { wrapper: null })}
                  </p>
                </div>
              )}
              {r.type === 'gallery' && (
                <GalleryParser resources={r.resources} subType={r.subType} />
              )}
            </Fragment>
          ))}
        </div>
      )
    }

    if (type === 'text') {
      returnThis = (
        <p className={classnames(subType)}>
          {compiler(content, { wrapper: null })}
        </p>
      )
    }

    if (type === 'title') {
      returnThis = <h2 className={classnames(subType)}>{content}</h2>
    }

    if (type === 'sub-title') {
      returnThis = <h3 className={classnames(subType)}>{content}</h3>
    }

    if (type === 'image') {
      const image = getImage(resource)

      if (isNull(caption) === false) {
        returnThis = (
          <div className={classnames('flow with-caption', subType)}>
            <GatsbyImage className={classnames(imgSubType)} image={image} />
            <p className="caption">{compiler(caption, { wrapper: null })}</p>
          </div>
        )
      } else {
        returnThis = (
          <div className={classnames('flow', subType)}>
            <GatsbyImage image={image} />
          </div>
        )
      }
    }

    if (type === 'gallery') {
      if (isNull(caption) === false) {
        returnThis = (
          <div className={classnames('flow with-caption', subType)}>
            <GalleryParser resources={resources} subType={subType} />
            <p className="caption">{compiler(caption, { wrapper: null })}</p>
          </div>
        )
      } else {
        returnThis = <GalleryParser resources={resources} subType={subType} />
      }
    }

    if (type === 'video') {
      returnThis = (
        <Video className={classnames('flow', subType)} url={content} />
      )
    }

    if (type === 'audio') {
      returnThis = (
        <Audio className={classnames('flow', subType)} url={content} />
      )
    }
  }

  return returnThis
}

/** Wrapper */
const Wrapper = ({ block, locale }) => {
  const {
    type,
    subType = undefined,
    contentBlocks: childContentBlocks = {},
  } = block

  let returnThis = <Fragment />

  if (type === 'block') {
    returnThis = (
      <div className={classnames('block', 'flow', subType)}>
        <BlockParser block={childContentBlocks} locale={locale} />
      </div>
    )
  } else {
    returnThis = <BlockParser block={block} locale={locale} />
  }

  return returnThis
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Wrapper
